<div class="modal-header1 text-left row mb-1">
    <h6 class="modal-title">Recupere su cuenta</h6>
    <button type="button" (click)="close()" class="close mt--5 mr--2">
        <i class="fa fa-times icon-modal-close"></i>
    </button>
</div>

<mat-dialog-content class="mat-typography">
    <form class="form-horizontal" [formGroup]="form" autocomplete="off">
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-12">
                        <strong>Ingrese su correo electrónico para buscar su cuenta</strong>
                    </div>
                </div>
                <div class="row">
                    <mat-form-field class="col-md-12" appearance="outline" style="border: 2px #000000;">
                        <mat-label>Correo</mat-label>
                        <input matInput name="email" formControlName="email">
                    </mat-form-field>
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>

<div mat-dialog-actions align="end" class="dialog-footer">
    <button class="btn btn-fill btn-info btn-md"  [disabled]="!form.valid || loading" type="button">
        Aceptar 
        <img *ngIf="loading" style="width: 18px; height: 18px;" src="assets/img/icons/preloader2.gif">
    </button>
</div>