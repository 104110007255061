import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styles: []
})
export class NavbarComponent implements OnInit {

  constructor(
    public router: Router
  ) { }

  ngOnInit() {

  }

  public cerrarSesion(){    
    localStorage.removeItem("clientBTC");
    localStorage.removeItem("tokenBTC");
    localStorage.removeItem("cantonBTC");
    localStorage.removeItem("ciudadBTC");

    this.router.navigate(['/login']).then(() => {
      window.location.reload();
    });
  }
}
