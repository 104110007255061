import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PasswordComponent } from './password/password.component';
import { ApiService } from 'src/app/services/api.service';
import { showAlert } from 'src/app/utils/swal-alert';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  title = 'BLUETRACK';
  public identity;
  public token;
  public showPassword: boolean = false;

  longitude;
  latitude;

  envios;
  detalles;
  selectEnvio;
  estados;
  rastreo;
  estadoSol = 'Aprobadas'
  viewData = false;

  form: FormGroup;

  @ViewChild('closebutton',  {static: false}) closebutton;
  @ViewChild('closebuttonReg',  {static: false}) closebuttonReg;

  constructor(
    public router: Router,
    public fb: FormBuilder,
    public dialog: MatDialog,
    public api: ApiService
  ) {
    this.createForm();
  }

  ngOnInit(): void {
  }


  createForm(){
    this.form = this.fb.group({
      password: ['',  [Validators.required]],
      user: ['',  [Validators.required]],
    });
  }

  login(){
    this.api.login(this.form.value).subscribe(resp => {
      localStorage.setItem("tokenBT", resp.body['token']);
      localStorage.setItem("sucursalBT", JSON.stringify(resp.body['operator'].branchOffice));
      localStorage.setItem("empresaBT", JSON.stringify(resp.body['operator'].branchOffice['business']));
      localStorage.setItem("idBT", resp.body['operator']._id);
      
      this.router.navigate(['/home']);

    }, (err) => {
      showAlert('error', 'Error', 'Credenciales incorrectas', 'btn btn-info');
    });
  }

  openRecoverPassword(){
    this.dialog.open(PasswordComponent, { width: '600px', data: this.form.value.email });
  }

  togglePasswordVisibility(){
    this.showPassword = !this.showPassword;
  }

}
