import { Component, OnInit } from '@angular/core';
// import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styles: []
})
export class SidebarComponent implements OnInit {
  business;
  branchOffice;
  public nombreEmpresa: string;
  public nombreSucursal: string;


  constructor(
    public router: Router,
  ) { }

  ngOnInit() {
    this.business = JSON.parse(localStorage.getItem("empresaBT"));
    this.branchOffice = JSON.parse(localStorage.getItem("sucursalBT"));
    this.nombreSucursal = this.branchOffice['name'];
    this.nombreEmpresa = this.business['businessName'];
  }  

  changeHistory(type){
    this.router.navigate([`/history/${type}`]).then(() => {
      window.location.reload();
    });
  }
}
