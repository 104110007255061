import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { URL_SERVICES } from '../configurations/url.service';
import { headersWithToken, headersWithoutToken } from '../utils/constants';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private _http: HttpClient) { }

  // Obtener Token
  getToken() {
    return localStorage.getItem('tokenBTC');
  }

  getIdBrachOffice(){
    return JSON.parse(localStorage.getItem('sucursalBT'))._id;
  }

  getCantonBranchOffice(){
    return JSON.parse(localStorage.getItem('sucursalBT')).canton;
  }

  getIdBusiness(){
    return JSON.parse(localStorage.getItem('empresaBT'))._id;
  }

  getIdOperator(){
    return localStorage.getItem('idBT');
  }

  // Servicios de sesión
  // getSession() {
  //   const headers = new HttpHeaders({ 
  //     'Content-Type': 'application/json',
  //     'Authorization': `Bearer ${this.getToken()}`
  //   });

  //   const url = URL_SERVICES + 'user/session';

  //   return this._http.get(url, { headers, observe: 'response' });
  // }

  getProfile(){
    const url = URL_SERVICES + `operator/id/${this.getIdOperator()}`;
    
    return this._http.get(url, { headers: headersWithToken, observe: 'response' });
  }

  login(body) {
    const url = URL_SERVICES + `operator/login`;
    
    return this._http.post(url, body, { headers: headersWithoutToken, observe: 'response' });
  }

  changePassword(body){
    const url = URL_SERVICES + `operator/password/${this.getIdOperator()}`;

    return this._http.put(url, body, { headers: headersWithToken, observe: 'response' });
  }

  logOut() {
    const url = URL_SERVICES + `user/logout`;

    return this._http.delete(url, { headers: headersWithToken, observe: 'response' });
  }

  register(client){
    const url = URL_SERVICES + 'client';
  
    return this._http.post( url, client, {headers: headersWithoutToken});
  }

  getCantonByName(name){
    const url = URL_SERVICES + `canton/search/${name}`;

    return this._http.get( url, {headers: headersWithToken, observe: 'response'} )
  }

  getProvinces(){
    const url = URL_SERVICES + 'province';

    return this._http.get( url, {headers: headersWithoutToken, observe: 'response'} )
  }

  getCities(province){
    const url = URL_SERVICES + `canton/${province}`;

    return this._http.get( url, {headers: headersWithoutToken, observe: 'response'} )
  }

  getLocationByAddress(address) {
    const url = `https://bee.com.ec/test/map/${address}`;

    return this._http.get(url, { headers: headersWithoutToken, observe: 'response' });
  }

  getRequests(status){
    const url = URL_SERVICES + `request/sucursal/${this.getIdBrachOffice()}/${status}`;

    return this._http.get(url, { headers: headersWithToken, observe: 'response' })
  }

  getShipmentsUnsettled(){
    const url = URL_SERVICES + `shippingAgreement/hoy/${this.getIdBrachOffice()}`;

    return this._http.get(url, { headers: headersWithToken, observe: 'response' })
  }
  
  getDetailRequest(idRequest){
    const url = URL_SERVICES + 'requestDetail/' + idRequest;
    
    return this._http.get(url, { headers: headersWithToken, observe: 'response' });
  }

  getDetailShipment(idShipment){
    const url = URL_SERVICES + 'agreementDetail/' + idShipment;
    
    return this._http.get(url, {headers: headersWithoutToken, observe: 'response'});
  }

  rastrearEnvio(shipment){
    const url = URL_SERVICES + `shipment-tracking/${shipment}`;

    return this._http.get(url, {headers: headersWithoutToken, observe: 'response'});
  }

  cancelarRequest(idRequest){
    const url = URL_SERVICES + `request-cancel/${idRequest}`;

    return this._http.delete(url, { headers: headersWithToken, observe: 'response' });
  }

  cancelarEnvio(idShipping){
    const url = URL_SERVICES + `shippingAgreement-cancel/${idShipping}`;

    return this._http.delete(url, { headers: headersWithToken, observe: 'response' });
  }

  searchRequest(body){
    const url = URL_SERVICES + `request-search/branchOffice`;

    const params = new HttpParams()
      .set('sucursal', body.sucursal)
      .set('status', body.status)
      .set('value', body.value)
      .set('from', body.from)
      .set('to', body.to);

    return this._http.get(url, {params, headers: headersWithToken, observe: 'response' });
  }

  searchShipment(body){
    let data = btoa(JSON.stringify(body));

    const url = URL_SERVICES + `shipment-search/branchOffice/${data}`;
    
    return this._http.get(url, { headers: headersWithToken, observe: 'response' });
  }

  getOperators(word=""){
    let parameter = "";

    if (word !== "") {
      parameter = `?word=${word}`;  
    }

    const url = URL_SERVICES + `operator/${this.getIdBrachOffice()}${parameter}`;
    

    return this._http.get(url, { headers: headersWithToken, observe: 'response' });
  }

  getCarriers(word=""){
    let parameter = "";

    if (word !== "") {
      parameter = `?word=${word}`;  
    }

    const url = URL_SERVICES + `carrier/${this.getIdBusiness()}${parameter}`;
    
    return this._http.get(url, { headers: headersWithToken, observe: 'response' });
  }

  getAvailableCarrier(){
    const url = URL_SERVICES + 'carrier/disponible/' + this.getIdBusiness();
    
    return this._http.get(url, { headers: headersWithToken, observe: 'response' })
  }

  postOperator(body){
    const url = URL_SERVICES + 'operator';

    return this._http.post(url, body, { headers: headersWithToken, observe:  'response' });
  }

  postCarrier(body){
    const url = URL_SERVICES + 'carrier';

    return this._http.post(url, body, { headers: headersWithToken, observe:  'response' });
  }

  putOperator(id, body){
    const url = URL_SERVICES + `operator/${id}`;

    return this._http.put(url, body, {headers: headersWithToken, observe: 'response'});
  }

  putCarrier(id, body){
    const url = URL_SERVICES + `carrier/${id}`;

    return this._http.put(url, body, {headers: headersWithToken, observe: 'response'});
  }

  getRoutes(){
    const url = URL_SERVICES + `destCanton/${this.getIdBrachOffice()}`;
    
    return this._http.get(url, { headers: headersWithToken, observe: 'response' });
  }

  postRoutes(body){
    const url = URL_SERVICES + 'destCanton';

    return this._http.post(url, body, { headers: headersWithToken, observe: 'response' });
  }

  changeStatusRoute(id, estado){
    const url = URL_SERVICES + `destCanton/${id}/${estado}`;

    return this._http.delete(url, { headers: headersWithToken, observe:  'response' });
  }

  getAgreements(word = ""){
    let parameter = "";

    if (word !== "") {
      parameter = `?word=${word}`;
    }
    
    const url = URL_SERVICES + `agreement/${this.getIdBrachOffice()}${parameter}`;

    return this._http.get(url, { headers: headersWithToken, observe: 'response' });
  }

  postAgreement(body){
    body.branchOffice =  this.getIdBrachOffice();
    body.cantonOrigen  =  this.getCantonBranchOffice();
    
    const url = URL_SERVICES + 'agreement';

    return this._http.post(url, body, { headers: headersWithToken, observe: 'response' });
  }

  putAgreement(id, body){
    const url = URL_SERVICES + `agreement/${id}`;

    return this._http.put(url, body, { headers: headersWithToken, observe: 'response' });
  }

  changeStatusAgreement(id, status){
    const  url = URL_SERVICES  + `agreement/${id}/${status}`;

    return  this._http.delete(url, { headers: headersWithToken, observe: 'response'});
  }

  clientByEmailOrUser(value){
    const url = URL_SERVICES + `client/searchMail/${value}`;

    return this._http.get(url, { headers: headersWithToken, observe: 'response' });
  }

  postShipment(body){
    const url = URL_SERVICES + 'shippingAgreement';

    return this._http.post(url, body, { headers: headersWithToken, observe: 'response' });
  }

  putShipment(shipment, body){
    const url = URL_SERVICES + `shippingAgreement/${shipment}`;

    return this._http.put(url, body, { headers: headersWithToken, observe: 'response' });
  }

  postStatusShipment(body){
    const url = URL_SERVICES + 'status';
    
    return this._http.post(url, body, { headers: headersWithToken, observe: 'response' });
  }

  changeStatusRequest(id: string, status: string, comment){
    let body = {
      status,
      comment
    }

    const url = URL_SERVICES + 'request/' + id;
    
    return this._http.put(url, body, { headers: headersWithToken, observe: 'response' });
  }

  reportShipment(body){
    const params = new HttpParams()
      .set('branchOffice', this.getIdBrachOffice())
      .set('value', body.value)
      .set('from', body.from)
      .set('to', body.to);

    const url = URL_SERVICES + `shipment-report`;
    
    return this._http.get(url, {params, responseType: 'blob',  headers: headersWithToken });
  }

  reportTotalShipments(body){
    const params = new HttpParams()
      .set('branchOffice', this.getIdBrachOffice())
      .set('value', body.value)
      .set('from', body.from)
      .set('to', body.to);

    const  url = URL_SERVICES + `shipment-reportList`;

    return this._http.get(url, {params, headers: headersWithToken, observe: 'response' });
  }

  getGuidesByCarrier(carrier, status){
    const url = URL_SERVICES + `shippingAgreement/transPend/${carrier}/${status}`;

    return this._http.get(url, {headers:  headersWithToken, observe: 'response'});
  }

  shipmentImages(id){
    const url = URL_SERVICES + `shipment-images/${id}`;

    return this._http.get(url,  {headers: headersWithToken, observe: 'response'});
  }

  getClientsByBranchOffice(){
    const url = URL_SERVICES + `clients-byBrachOffice/${this.getIdBrachOffice()}`;
    
    return this._http.get(url,  {headers: headersWithToken, observe: 'response'});
  }
}
