import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './pages/login/login.component';
// import { SessionInService } from './services/session-in/session-in.service';
// import { SessionOutService } from './services/session-out/session-out.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
    // canActivate: [SessionInService]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    // canActivate: [SessionOutService],
    children: [
      {
        path: 'profile',
        loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule),
      },
      {
        path: 'home',
        loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'history/:type',
        loadChildren: () => import('./pages/history/history.module').then(m => m.HistoryModule),
      },
      {
        path: 'operator',
        loadChildren: () => import('./pages/operator/operator.module').then(m => m.OperatorModule),
      },
      {
        path: 'carrier',
        loadChildren: () => import('./pages/carrier/carrier.module').then(m => m.CarrierModule),
      },
      {
        path: 'map',
        loadChildren: () => import('./pages/map/map.module').then(m => m.MapModule),
      },
      {
        path: 'route',
        loadChildren: () => import('./pages/routes/routes.module').then(m => m.RoutesModule),
      },
      {
        path: 'agreement',
        loadChildren: () => import('./pages/agreement/agreement.module').then(m => m.AgreementModule),
      },
      {
        path: 'report',
        loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule),
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'login'
  }
];


@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
    useHash: true,
    relativeLinkResolution: 'legacy'
})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
