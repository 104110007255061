<div class="sidebar" data-color="bluetrack" data-image="assets/img/full-screen-image-3.jpg">
    <div class="logo">
        <a class="simple-text">
            <img src="assets/img/blue/sidebar.png" height="150px" style="margin-left: 50px;">
        </a>
    </div>

    <div class="sidebar-wrapper">
        <div class="user">
            <div class="info">
                <div class="photo" style="margin-top: 10px;">
                    <img src="assets/img/blue/avatar.png" />
                </div>

                <a data-toggle="collapse" href="#collapseExample" class="collapsed">
                    <span style="font-size: 12px;">
                        {{ nombreEmpresa }}
                        <b class="caret"></b>
                    </span>
                    <span style="font-size: 10px;">
                        {{ nombreSucursal }}
                        
                    </span>
                </a>

                <div class="collapse" id="collapseExample">
                    <ul class="nav">
                        <li routerLinkActive="active">
                            <a routerLink="/profile">
                                <span class="sidebar-mini">MP</span>
                                <span class="sidebar-normal">Mi Perfil</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <ul class="nav">
            
            <li routerLinkActive="active">
                <a routerLink="/home">
                    <i class="pe-7s-home"></i>
                    <p>Inicio</p>
                </a>
            </li>

            <li routerLinkActive="active">
                <a data-toggle="collapse" href="#tablesHistory">
                    <i class="pe-7s-file"></i>
                    <p>
                        Historial
                        <b class="caret"></b>
                    </p>
                </a>
                <div class="collapse" id="tablesHistory">
                    <ul class="nav" style="margin-left: 45px;">
                        <li routerLinkActive="active">
                            <a (click)="changeHistory('request')">
                                <span class="sidebar-normal">Solicitudes</span>
                            </a>
                        </li>
                        <li routerLinkActive="active">
                            <a (click)="changeHistory('shipment')">
                                <span class="sidebar-normal">Envíos</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </li>
            <li routerLinkActive="active">
                <a routerLink="/operator">
                    <i class="pe-7s-users"></i>
                    <p>Operador Logístico</p>
                </a>
            </li>
            <li routerLinkActive="active">
                <a data-toggle="collapse" href="#tablesExamples">
                    <i class="pe-7s-car"></i>
                    <p>Transportistas
                        <b class="caret"></b>
                    </p>
                </a>
                <div class="collapse" id="tablesExamples">
                    <ul class="nav" style="margin-left: 45px;">
                        <li routerLinkActive="active">
                            <a routerLink="/carrier" routerLinkActive="router-link-active" >
                                <span class="sidebar-normal">Listado</span>
                            </a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/map">
                                <span class="sidebar-normal">Mapa</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </li>
            <li routerLinkActive="active">
                <a routerLink="/agreement">
                    <i class="pe-7s-news-paper"></i>
                    <p>Convenios</p>
                </a>
            </li>
            <li routerLinkActive="active">
                <a routerLink="/route">
                    <i class="pe-7s-map-2"></i>
                    <p>Rutas Cantones</p>
                </a>
            </li>
            <li routerLinkActive="active">
                <a routerLink="/report">
                    <i class="pe-7s-graph"></i>
                    <p>Reportes</p>
                </a>
            </li>
        </ul>
    </div>
</div>